<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" class="pt-0">
        <v-card class="elevation-0">
          <v-card-title class="pa-4">
            <v-container fluid>
              <v-row no-gutters>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-autocomplete
                    v-model="selectedVehicles"
                    label="Select Vehicles"
                    chips
                    multiple
                    :items="vehicles"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  lg="4"
                  xl="4"
                  offset-md="2"
                  offset-lg="2"
                  offset-xl="2"
                >
                  <v-select
                    v-model="reportType"
                    :items="reportItems"
                    label="Select Report"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                  <div class="float-right">
                    <fab-button v-if="this.selectedVehicles.length <= 5"
                      @click="getData()"
                      dark
                      top
                      xsmall
                      :icon="'mdi-play'"
                      :tooltipSpan="'Generate Report'"
                    ></fab-button
                    >&nbsp;
                    <fab-button
                      @click="downloadExcel()"
                      dark
                      top
                      xsmall
                      :icon="'mdi-file-excel'"
                      :tooltipSpan="'Download Report'"
                    ></fab-button
                    >&nbsp;
                    <fab-button
                      @click="getData()"
                      dark
                      top
                      xsmall
                      :icon="'mdi-refresh'"
                      :tooltipSpan="'Refresh'"
                    ></fab-button>
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-menu
                    v-model="menuAppointmentDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="appointmentDate"
                        label="Appointment Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="appointmentDate"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      @input="menuAppointmentDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>
        </v-card>
        <v-divider></v-divider>
        <v-data-table
          dense
          :headers="headers"
          :items="items"
          class="elevation-1"
          disable-pagination
          hide-default-footer
          :server-items-length="items.length"
        >
          <template v-if="items.length === 0" v-slot:no-data>
            <v-container>
              <v-row>
                <v-col md="6" offset-md="3">
                  <span class="title font-weight-bold">NO DATA AVAILABLE.</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody v-for="(item, index) in items" :key="index">
              <tr v-for="(obj, i) in item.outlets" :key="i">
                <td
                  class="text-center"
                  :style="i === 0 ? '' : 'display: none;'"
                >
                  {{ index + 1 }}
                </td>
                <td
                  class="text-no-wrap text-uppercase"
                  :style="i === 0 ? '' : 'display: none;'"
                >
                  {{ item.ticket_no }}
                </td>
                <td
                  class="text-no-wrap text-uppercase"
                  :style="i === 0 ? '' : 'display: none;'"
                >
                  {{ item.vehicle === null ? "--" : item.vehicle.plate_no }}
                </td>
                <td
                  :style="i === 1 ? 'border-bottom: none;' : 'display: none;'"
                  colspan="3"
                  :rowspan="item.outlets.length"
                ></td>
                <td class="text-no-wrap text-uppercase">
                  {{ obj.outlet_name_1 }}
                </td>
                <td class="text-no-wrap text-uppercase">
                  {{ obj.outlet_address_1 ? obj.outlet_address_1 : "--" }}
                </td>
                <td class="text-center text-no-wrap text-uppercase">
                  {{ obj.eda ? obj.eda : "--" }}
                </td>
                <td class="text-center text-no-wrap text-uppercase">
                  {{ obj.eta ? obj.eta : "--" }}
                </td>
                <td class="text-no-wrap text-uppercase">
                  {{ obj.outlet_name_2 }}
                </td>
                <td class="text-no-wrap text-uppercase">
                  {{ obj.outlet_address_2 }}
                </td>
                <td class="text-center text-no-wrap text-uppercase">
                  {{ obj.ada }}
                </td>
                <td class="text-center text-no-wrap text-uppercase">
                  {{ obj.ata }}
                </td>
                <td class="text-center text-no-wrap text-uppercase">
                  {{ obj.add }}
                </td>
                <td class="text-center text-no-wrap text-uppercase">
                  {{ obj.atd }}
                </td>
                <!-- <td class="text-no-wrap text-uppercase">{{ obj.km_run }}</td> -->
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SubCompanyServices from "@/services/SubCompanyServices";
import Services from "@/services/CompanyServices";
import XLSX from "xlsx";

export default {
  data: () => {
    return {
      title: "Dispatch Monitoring",
      headers: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        {
          text: "Dispatch Ticket No",
          sortable: false,
        },
        { text: "Plate No", sortable: false },
        { text: "Outlet", sortable: false },
        { text: "Location", sortable: false },
        { text: "EDD/EDA", sortable: false, align: "center" },
        { text: "ETD/ETA", sortable: false, align: "center" },
        { text: "Outlet", sortable: false },
        { text: "Location", sortable: false },
        { text: "ADA", sortable: false, align: "center" },
        { text: "ATA", sortable: false, align: "center" },
        { text: "ADD", sortable: false, align: "center" },
        { text: "ATD", sortable: false, align: "center" },
        // { text: "KM Run", sortable: false },
      ],
      items: [],
      data: {},
      queryParams: {
        search: "",
        // limit: 10,
        // offset: 0
      },
      vehicleQueryParams: {
        search: "",
      },
      rules: {
        required: (value) => !!value || "This field is required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      selectedVehicles: [],
      vehicles: [],
      snailtrailHeaders: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        { text: "Date", sortable: false, align: "center" },
        { text: "Time", sortable: false, align: "center" },
        { text: "Remarks", sortable: false, align: "center" },
        // { text: "Status", sortable: false, align: "center" },
        { text: "Engine", sortable: false, align: "center" },
      ],
      snailtrailItems: [],
      viewSnailTrailDialog: false,
      fullWidthDialogLoading: false,
      reportItems: [
        {
          text: "Detailed Report",
          value: "/c/s/detailed-report",
        },
        {
          text: "Dispatch Alarms",
          value: "/c/s/dispatch-alarms",
        },
      ],
      menuAppointmentDate: false,
      appointmentDate: new Date().toISOString().substr(0, 10),
    };
  },
  created: async function() {
    if (this.$store.state.token !== null) {
      this.$store.dispatch("startLoading");

      this.$store.dispatch("dispatchReportTypeText", "Detailed Report");

      this.selectedVehicles = this.$store.state.reportVehiclesArray;

      const vehiclesItems = await this.setVehicles();

      const returnedVehicles = await Promise.all(
        vehiclesItems.map((item) => {
          if (item.gps_device) {
            return {
              text: item.plate_no.toUpperCase(),
              value: item.id,
            };
          }
        })
      );

      this.vehicles = returnedVehicles.filter((item) => {
        return item;
      });

      this.$store.dispatch("endLoading");
    } else {
      this.$router.push({
        path: "/",
      });

      this.$store.dispatch("clearStore");
    }
  },
  computed: {
    reportType: {
      get() {
        // return this.$store.state.reportTypeValue;
        return "/c/s/detailed-report";
      },
      set(val) {
        this.items.length = 0;
        this.$store.dispatch("dispatchReportTypeValue", val);
        this.$router.push({
          path: val,
        });
      },
    },
  },
  watch: {
    selectedVehicles: function() {
      this.$store.dispatch(
        "dispatchReportVehiclesArray",
        this.selectedVehicles
      );
    },
  },
  methods: {
    search(value) {
      this.queryParams.search = value;
      this.setEmployees(this.queryParams);
    },
    async getData() {
      this.$store.dispatch("startLoading");

      const body = {
        vehicles: this.selectedVehicles,
        appointment_date: this.$dateFormat(this.appointmentDate, "YYYY-MM-DD"),
        company_id: this.$store.state.company.id,
      };

      try {
        let response = (await Services.getDetailedReport(body)).data;

        this.items = response.data;

        this.$store.dispatch("dispatchHasItems", true);
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
        // else if (error.response.status) {
        //   this.$store.dispatch("dispatchDataToLarge", true);
        // }
        else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }

      this.$store.dispatch("endLoading");
    },
    // async getData() {
    //   this.$store.dispatch("startLoading");

    //   this.queryParams.company_id = this.$store.state.company.id;
    //   this.queryParams.appointment_date = this.appointmentDate;
    //   this.queryParams.current_module = "outlet-dispatch-report";

    //   try {
    //     let response = (await Services.getTrackingDispatches(this.queryParams))
    //       .data;

    //     this.items = response.data;
    //   } catch (error) {
    //     if (!error.response) {
    //       this.$infoBar({
    //         type: "error",
    //         text: "No network or server connection",
    //       });
    //     } else {
    //       this.$infoBar({
    //         type: "error",
    //         text: this.titleCase(error.response.data.message) + "!",
    //       });
    //     }
    //   }

    //   this.$store.dispatch("endLoading");
    // },
    async setVehicles() {
      let data;

      try {
        this.vehicleQueryParams.sub_company_id = this.$store.state.subcompany.id;

        const response = await SubCompanyServices.getVehicles(
          this.vehicleQueryParams
        );
        data = response.data.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }
      return data;
    },
    async downloadExcel() {
      let dataToDownload = [];
      let header = [];
      let locationLengths = [];

      for (let i = 0; i < this.headers.length; i++) {
        header.push(this.headers[i].text);
      }

      header.splice(0, 1);

      dataToDownload.push(header);

      for (let i = 0; i < this.items.length; i++) {
        for (let e = 0; e < this.items[i].outlets.length; e++) {
          let theItem = [];

          if (e === 0) {
            theItem.push(this.items[i].ticket_no);
            theItem.push(this.items[i].vehicle.plate_no.toUpperCase());

            if (
              "outlet_name_1" &&
              "outlet_address_1" &&
              "eda" &&
              "eta" in this.items[i].outlets[e]
            ) {
              theItem.push(
                this.items[i].outlets[e].outlet_name_1.toUpperCase()
                  ? this.items[i].outlets[e].outlet_name_1.toUpperCase()
                  : ""
              );
              theItem.push(
                this.items[i].outlets[e].outlet_address_1
                  ? this.items[i].outlets[e].outlet_address_1.toUpperCase()
                  : ""
              );
              theItem.push(
                this.items[i].outlets[e].eda ? this.items[i].outlets[e].eda : ""
              );
              theItem.push(
                this.items[i].outlets[e].eta ? this.items[i].outlets[e].eta : ""
              );
            } else {
              theItem.push("");
              theItem.push("");
              theItem.push("");
              theItem.push("");
            }

            theItem.push(
              this.items[i].outlets[e].outlet_name_2
                ? this.items[i].outlets[e].outlet_name_2.toUpperCase()
                : ""
            );
            theItem.push(
              this.items[i].outlets[e].outlet_address_2
                ? this.items[i].outlets[e].outlet_address_2.toUpperCase()
                : ""
            );
            theItem.push(
              this.items[i].outlets[e].ada ? this.items[i].outlets[e].ada : ""
            );
            theItem.push(
              this.items[i].outlets[e].ata ? this.items[i].outlets[e].ata : ""
            );
            theItem.push(
              this.items[i].outlets[e].add ? this.items[i].outlets[e].add : ""
            );
            theItem.push(
              this.items[i].outlets[e].atd ? this.items[i].outlets[e].atd : ""
            );
            theItem.push(
              this.items[i].outlets[e].km_run
                ? this.items[i].outlets[e].km_run
                : ""
            );
          } else {
            theItem.push("");
            theItem.push("");

            if (
              "outlet_name_1" &&
              "outlet_address_1" &&
              "eda" &&
              "eta" in this.items[i].outlets[e]
            ) {
              theItem.push(
                this.items[i].outlets[e].outlet_name_1.toUpperCase()
                  ? this.items[i].outlets[e].outlet_name_1.toUpperCase()
                  : ""
              );
              theItem.push(
                this.items[i].outlets[e].outlet_address_1
                  ? this.items[i].outlets[e].outlet_address_1.toUpperCase()
                  : ""
              );
              theItem.push(
                this.items[i].outlets[e].eda ? this.items[i].outlets[e].eda : ""
              );
              theItem.push(
                this.items[i].outlets[e].eta ? this.items[i].outlets[e].eta : ""
              );
            } else {
              theItem.push("");
              theItem.push("");
              theItem.push("");
              theItem.push("");
            }

            theItem.push(
              this.items[i].outlets[e].outlet_name_2
                ? this.items[i].outlets[e].outlet_name_2.toUpperCase()
                : ""
            );
            theItem.push(
              this.items[i].outlets[e].outlet_address_2
                ? this.items[i].outlets[e].outlet_address_2.toUpperCase()
                : ""
            );
            theItem.push(
              this.items[i].outlets[e].ada ? this.items[i].outlets[e].ada : ""
            );
            theItem.push(
              this.items[i].outlets[e].ata ? this.items[i].outlets[e].ata : ""
            );
            theItem.push(
              this.items[i].outlets[e].add ? this.items[i].outlets[e].add : ""
            );
            theItem.push(
              this.items[i].outlets[e].atd ? this.items[i].outlets[e].atd : ""
            );
            theItem.push(
              this.items[i].outlets[e].km_run
                ? this.items[i].outlets[e].km_run
                : ""
            );
          }

          locationLengths.push(
            this.items[i].outlets[e].outlet_address_1
              ? this.items[i].outlets[e].outlet_address_1.length
              : 0
          );
          locationLengths.push(
            this.items[i].outlets[e].outlet_address_2
              ? this.items[i].outlets[e].outlet_address_2.length
              : 0
          );

          dataToDownload.push(theItem);
        }
      }

      // don't delete any codes after this comment got it?!

      let wscols = [
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: this.biggestNumberInArray(locationLengths) }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: this.biggestNumberInArray(locationLengths) }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
        { wch: 25 }, // "pixels"
      ];

      let wsrows = [];

      let ws = XLSX.utils.aoa_to_sheet(dataToDownload, { cellDates: true });
      let wb = XLSX.utils.book_new();

      let fmt = "#,##0";

      let range = XLSX.utils.decode_range(ws["!ref"]);
      for (let e = range.s.r + 1; e <= range.e.r; ++e) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          let ref = XLSX.utils.encode_cell({ r: e, c: C });
          if (!ws[ref]) continue;
          if (ws[ref].t !== "n") continue;
          ws[ref].z = fmt;
        }
      }

      XLSX.utils.book_append_sheet(wb, ws);

      ws["!cols"] = wscols;
      ws["!rows"] = wsrows;

      wb.Props = {
        Title: this.title,
        Subject: this.title,
        Author: "Developer of Journeytech",
        Company: "Journeytech",
        Category: "Reports",
      };

      // ws["!protect"] = {
      //   password: "Journeytech@1",
      //   formatRows: false,
      //   formatColumns: false,
      //   objects: true,
      //   scenarios: true
      // };

      if (!wb.Workbook) wb.Workbook = { Sheets: [], WBProps: {} };
      if (!wb.Workbook.WBProps) wb.Workbook.WBProps = {};
      wb.Workbook.WBProps.filterPrivacy = true;

      let filenames = [
        [this.title + ".xlsx"],
        [this.title + ".xlsm"],
        [this.title + ".xlsb"],
        [this.title + ".xlam"],
        [this.title + ".biff8.xls", { bookType: "xls" }],
        [this.title + ".biff5.xls", { bookType: "biff5" }],
        [this.title + ".biff2.xls", { bookType: "biff2" }],
        [this.title + ".xml.xls", { bookType: "xlml" }],
        [this.title + ".xla"],
        [this.title + ".ods"],
        [this.title + ".fods"],
        [this.title + ".csv"],
        [this.title + ".txt"],
        [this.title + ".slk"],
        [this.title + ".eth"],
        [this.title + ".htm"],
        [this.title + ".dif"],
        [this.title + ".dbf", { sheet: "Hidden" }],
        [this.title + ".rtf"],
        [this.title + ".prn"],
      ];
      let OUT = ["base64", "binary", "string", "array"];
      if (typeof Buffer !== "undefined") OUT.push("buffer");
      filenames.forEach(function(r) {
        XLSX.writeFile(wb, r[0], r[1]);
        if (typeof process !== "undefined") XLSX.readFile(r[0]);

        let ext = (r[1] && r[1].bookType) || r[0].split(".")[1];
        ext = { htm: "html" }[ext] || ext;
        OUT.forEach(function(type) {
          if (
            type === "string" &&
            [
              "xlsx",
              "xlsm",
              "xlsb",
              "xlam",
              "biff8",
              "biff5",
              "biff2",
              "xla",
              "ods",
              "dbf",
            ].indexOf(ext) > -1
          )
            return;
          if (
            type === "array" &&
            ["xlsx", "xlsm", "xlsb", "xlam", "ods"].indexOf(ext) > -1 &&
            typeof Uint8Array === "undefined"
          )
            return;
          let datout = XLSX.write(wb, {
            type: type,
            bookType: ext,
            sheet: (r[1] && r[1].sheet) || null,
          });
          XLSX.read(datout, { type: type });
          if (type === "array") console.log(ext, datout);
        });
      });
    },
    cutHeadings(data) {
      let text = null;
      text = data.length < 5 ? data : data.slice(0, 40) + "...";
      return text;
    },
    biggestNumberInArray(arr) {
      // The largest number at first should be the first element or null for empty array
      let largest = arr[0] || null;

      // Current number, handled by the loop
      let number = null;
      for (let i = 0; i < arr.length; i++) {
        // Update current number
        number = arr[i];

        // Compares stored largest number with current number, stores the largest one
        largest = Math.max(largest, number);
      }

      return largest;
    },
    titleCase(str) {
      let splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
  },
};
</script>

<style scoped>
tbody tr:hover {
  background-color: transparent !important;
}

.my-border td:nth-child(1) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(2) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(3) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(4) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(5) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(6) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(7) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(8) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(9) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(10) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(11) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(12) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(13) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(14) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(15) {
  border-top: 1px solid #dddddd;
}

.my-border td:nth-child(16) {
  border-top: 1px solid #dddddd;
}
</style>
